import {
  GroupOutletParams,
  GroupOutletDataTypes,
} from './types';

import axiosInstance from 'services/common/instance';

const getGroupOutlet = async (slug: string, params: GroupOutletParams)
  : Promise<GroupOutletDataTypes> => {
  const res = await axiosInstance.get(`group-outlets/${slug}`, { params });
  return res.data.data;
};

export default getGroupOutlet;
