import './App.scss';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

import Heading from 'components/atoms/Heading';
import MainLayout from 'components/templates/MainLayout';
import Home from 'pages/Home';
import { store } from 'store';

const App: React.FC = () => (
  <div className="app">
    <Router>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/:slug" element={<Home />} />
          <Route
            key="router-notfound"
            path="*"
            element={(
              <div className="notfound">
                <Heading type="h2" modifiers={['700', 'center']}>
                  Đường dẫn không hợp lệ, xin vui lòng thử lại!
                </Heading>
              </div>
            )}
          />
        </Route>
      </Routes>
    </Router>
  </div>
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>
);

export default AppWrapper;
