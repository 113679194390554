import React from 'react';

import Text from 'components/atoms/Text';

export interface HeaderProps {
  title?: string;
}

const Header: React.FC<HeaderProps> = ({
  title,
}) => (
  <div className="o-header">
    <Text modifiers={['16x24', '600', 'gunmetal']}>
      {title}
    </Text>
  </div>
);

export default Header;
