import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import {
  useQuery,
} from 'react-query';
import { useParams } from 'react-router-dom';

import Heading from 'components/atoms/Heading';
import Image from 'components/atoms/Image';
import Input from 'components/atoms/Input';
import Loading from 'components/atoms/Loading';
import Text from 'components/atoms/Text';
import Datepicker from 'components/molecules/Datepicker';
import ModalImage from 'components/organisms/ModalImage';
import PageLayout from 'components/templates/PageLayout';
import useDebounce from 'hooks/useDebounce';
import getGroupOutlet from 'services/outlect';
import { toLowerCaseString } from 'utils/functions';
import StringUtils from 'utils/removeDiacritics';

const Home: React.FC = () => {
  const { slug } = useParams();

  const [date, setDate] = useState<Date | null>();
  const [search, setSearch] = useState<string>('');
  const [isNotFound, setIsNotFound] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [activeIdx, setActiveIdx] = useState<number>(0);
  const [outletName, setOutletName] = useState('');
  const searchDebounce = useDebounce(search, 1000);

  const { data: groupDataRes, isFetching } = useQuery(
    ['getGroupOutlet', slug, date],
    () => getGroupOutlet(slug || '', {
      fromDate: date ? dayjs(date).format('DD/MM/YYYY') : '',
    }),
    {
      enabled: !!slug,
      onSuccess: (res) => {
        if (!outletName) {
          setOutletName(res.name);
        }
      },
      onError: (err) => {
        if (Array.isArray(err) && err.length > 0) {
          if (err[0].code === 404) {
            setIsNotFound(true);
          }
        }
      }
    },
  );

  const imageList = useMemo(() => {
    if (groupDataRes?.images && groupDataRes.images.length > 0) {
      return groupDataRes.images.map((item) => ({
        url: item.url,
        code: item.outlet.code,
        name: item.outlet.name,
      }));
    }
    return [];
  }, [groupDataRes]);

  const filteredList = useMemo(() => {
    if (searchDebounce) {
      const searchText = StringUtils.removeDiacritics(searchDebounce);
      return imageList.filter((item) => StringUtils.removeDiacritics(
        toLowerCaseString(item.code)
      ).includes(searchText)
        || StringUtils.removeDiacritics(
          toLowerCaseString(item.name)
        ).includes(searchText));
    }
    return imageList;
  }, [imageList, searchDebounce]);

  if (!slug) {
    return (
      <div className="p-home_invalid">
        <Heading type="h2" modifiers={['700', 'center']}>
          Đường dẫn không hợp lệ, xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  if (isNotFound) {
    return (
      <div className="p-home_invalid">
        <Heading type="h2" modifiers={['700', 'center']}>
          Không tìm thấy Group, xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  return (
    <PageLayout headerProps={{ title: outletName }}>
      <div className="p-home_filter u-mt-16">
        <div className="p-home_calendar">
          <Datepicker
            id="searchDate"
            label="Chọn ngày tìm kiếm"
            placeholderText="Chọn ngày"
            selected={date}
            onChange={setDate}
          />
        </div>
        <div className="p-home_input">
          <Input
            id="search"
            label="Tìm kiếm theo Outlet"
            placeholder="Nhập từ khoá"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
        </div>
      </div>
      <div className="u-mt-16">
        {(isFetching) && (
          <div className="p-home_loading">
            <Loading isShow variant="fullScreen" />
          </div>
        )}
        {(!isFetching && !filteredList.length) && (
          <div className="p-home_noData">
            <Text modifiers={['center', '16x24', 'eerieBlack', '600']}>
              Không có hình ảnh
            </Text>
          </div>
        )}
        <div className="p-home_imageList">
          {filteredList.map((item, idx) => (
            <div
              className="p-home_imageList-item"
              key={`outletImage-${String(idx)}`}
              onClick={() => {
                setActiveIdx(idx);
                setIsShowPopup(true);
              }}
            >
              <Image
                imgSrc={item.url}
                alt={`outletImage-${String(idx)}`}
                ratio="3x4"
                size="contain"
              />
            </div>
          ))}
        </div>
      </div>
      <ModalImage
        isOpen={!!isShowPopup}
        handleClose={() => {
          setIsShowPopup(false);
          setActiveIdx(0);
        }}
        listImage={[...filteredList].map((ele) => ele.url) || []}
        activeIdx={activeIdx}
      />
    </PageLayout>
  );
};
export default Home;
