import React from 'react';

import Header, { HeaderProps } from 'components/organisms/Header';

interface PageLayoutProps {
  children?: React.ReactNode;
  headerProps?: HeaderProps;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children, headerProps }) => (
  <div className="t-pageLayout">
    <div className="t-pageLayout_header">
      <Header {...headerProps} />
    </div>
    <div className="t-pageLayout_body">
      {children}
    </div>
  </div>
);

PageLayout.defaultProps = {
  children: undefined,
};

export default PageLayout;
